import React, { useState } from 'react';
import { providerTypes } from '../types/propTypes';

const defaultState = {
  introHidden: false,
  introTriggered: false,
};

const IntroContext = React.createContext(defaultState);

const IntroProvider = ({ children }) => {
  const [introHidden, setIntroHidden] = useState(defaultState.introHidden);
  const [introTriggered, setIntroTriggered] = useState(
    defaultState.introTriggered
  );
  const introHide = () => setIntroHidden(true);
  const introTrigger = () => setIntroTriggered(true);

  return (
    <IntroContext.Provider
      value={{
        introHidden,
        introHide,
        introTriggered,
        introTrigger,
      }}
    >
      {children}
    </IntroContext.Provider>
  );
};

IntroProvider.propTypes = providerTypes;

export default IntroContext;

export { IntroProvider };
