import React, { useContext, Fragment } from 'react';
import styled from 'styled-components';
import CookieConsent, { Cookies } from 'react-cookie-consent';

import Header from '../components/Header';
import Reboot from '../style/reboot';
import Global from '../style/global';
import { colors, z, gradient, font } from '../consts/style';
import { layoutTypes } from '../types/propTypes';
import IntroContext from '../store/introContext';
import Intro from '../components/Intro';
import VideoContext from '../store/videoContext';

import Video from '../components/Video';

const SiteWrapper = styled.div`
  padding-top: 20rem;
`;

const VideoModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ${z.video}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  background: ${colors.purple};

  button.close {
    width: 12rem;
    ${gradient.red};
    ${font.button};
    margin-bottom: 2rem;
    color: ${colors.grey};
    &.close {
      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
      width: 4rem;
      height: 4rem;
      &::after {
        content: '';
        height: 0.2rem;
        width: 3rem;
        background: ${colors.grey};
        transform: translateX(-50%) rotate(45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
      }
      &::before {
        content: '';
        height: 0.2rem;
        width: 3rem;
        background: ${colors.grey};
        transform: translateX(-50%) rotate(-45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
      }
    }
  }
`;

const Layout = ({ children, location }) => {
  const { introHidden } = useContext(IntroContext);
  const { videoShown, videoHide, videoData } = useContext(VideoContext);

  return (
    <Fragment>
      <Reboot />
      <Global />
      {videoShown && (
        <VideoModal>
          <Video videoId={videoData.id} provider={videoData.provider} />
          <button className="close" onClick={videoHide} />
        </VideoModal>
      )}

      {!introHidden && <Intro />}
      <Header location={location} shown={introHidden} />
      <SiteWrapper>
        {children}
        {Cookies.get('DistinctMarketing') !== 'true' && (
          <CookieConsent
            sameSite="strict"
            style={{ background: colors.dark }}
            buttonStyle={{ background: colors.light }}
            cookieName="DistinctMarketing"
            expires={365}
          >
            This website uses cookies to enhance the user experience. User data
            is shared with Facebook & Google for retargeting.
          </CookieConsent>
        )}
      </SiteWrapper>
    </Fragment>
  );
};

Layout.propTypes = layoutTypes;

export default Layout;
