import React from 'react';
import { IntroProvider } from './src/store/introContext';
import { VideoProvider } from './src/store/videoContext';

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <IntroProvider>
      <VideoProvider>{element}</VideoProvider>
    </IntroProvider>
  );
};
