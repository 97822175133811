import React, { useState } from 'react';
import { providerTypes } from '../types/propTypes';

const defaultState = {
  videoData: { id: null, provider: null },
};

const VideoContext = React.createContext(defaultState);

const VideoProvider = ({ children }) => {
  const [videoData, setVideoData] = useState(defaultState.videoData);

  const videoShow = (id, provider) => setVideoData({ id, provider });
  const videoHide = () => setVideoData(defaultState.videoData);

  return (
    <VideoContext.Provider
      value={{
        videoShow,
        videoShown: Boolean(videoData.id),
        videoHide,
        videoData,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

VideoProvider.propTypes = providerTypes;

export default VideoContext;

export { VideoProvider };
