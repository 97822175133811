import styled from 'styled-components';
import { colors, font } from '../consts/style';

export const Container = styled.div`
  padding: 0 2rem;
  width: 100%;
`;

export const Inner = styled.div`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
`;

export const InnerSmall = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
`;

export const SectionTitle = styled.h2`
  text-align: center;
  color: ${colors.grey};
  ${font.h1}
  margin-bottom: 3.2rem;
`;
