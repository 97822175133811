import React, { useState, useContext, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { z, bz, font, gradient, colors } from '../consts/style';
import useBrowser from '../hooks/useBrowser';
import { scrollTopNow } from '../utils/helpers';
import IntroContext from '../store/introContext';
import { videoTypes } from '../types/propTypes';
import DownSrc from '../images/down.svg';

import Mp4Src from '../images/distinct-min.mp4';
import WebmSrc from '../images/distinct.webm';

const Wrapper = styled.div`
  transition: all 1s;
  ${bz};
  position: fixed;
  top: ${props => (props.fading ? '-100%' : '0')};
  left: 0;
  height: 100%;
  width: 100%;
  ${z.hero};
  overflow: hidden;
  ${gradient.default};
`;

const Down = styled.button`
  transition: bottom 1s;
  ${bz};
  bottom: ${props => (props.triggered ? '-400px' : '2rem')};
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  z-index: 10;
  border: none;
  background: transparent;
  padding: 0;
  ${font.body};
  color: ${colors.grey};
  p {
    margin-bottom: 1.6rem;
  }
`;

const MobileImage = styled(Img)`
  ${bz};
  transition: all 1s;
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100%;
  position: absolute !important;
  z-index: 0;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
  &::before {
    content: '';
    background: rgba(0, 28, 53, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;
const introQuery = graphql`
  {
    datoCmsMisc {
      introTitle
      introImage {
        url
      }
      introMobileImage {
        fluid(maxWidth: 1800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`;

// Touch Variables
let initialX = null;
let initialY = null;

export default function Intro() {
  const [isDesktop, setDesktop] = useState(false);
  const [isModernBrowser, setIsModernBrowser] = useState(false);
  const { introTrigger, introHide, introTriggered } = useContext(IntroContext);
  const data = useStaticQuery(introQuery);
  const browser = useBrowser();
  const showVideo = isDesktop && isModernBrowser;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(browser && !browser.mobile);
      setIsModernBrowser(browser && !['edge', 'ie'].includes(browser.name));
      subscribeToListeners();
    }
    return () => {
      unsubscribeToListeners();
    };
  }, []);

  function subscribeToListeners() {
    setTouchListeners();
    setWheelListener();
  }

  function unsubscribeToListeners() {
    removeTouchListeners();
    removeWheelListener();
  }

  //*********** Event Listener Methods

  function setTouchListeners() {
    if (typeof window !== 'undefined') {
      window.addEventListener('touchstart', handleTouchStart, false);
      window.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
    }
  }

  function removeTouchListeners() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('touchstart', handleTouchStart, false);
      window.removeEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
    }
  }

  function setWheelListener() {
    if (typeof window !== 'undefined') {
      window.addEventListener('wheel', handleWheel, {
        passive: false,
      });
    }
  }

  function removeWheelListener() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('wheel', handleWheel, {
        passive: false,
      });
    }
  }

  //*********** Helper Methods

  function handleTouchStart(e) {
    initialX = e.touches[0].clientX;
    initialY = e.touches[0].clientY;
  }

  function handleTouchMove(e) {
    if (initialX === null) {
      return;
    }

    if (initialY === null) {
      return;
    }

    let currentX = e.touches[0].clientX;
    let currentY = e.touches[0].clientY;

    let diffX = initialX - currentX;
    let diffY = initialY - currentY;

    if (Math.abs(diffX) < Math.abs(diffY)) {
      diffY > 0 ? handleWheelDown() : handleWheelUp();
    }

    initialX = null;
    initialY = null;

    e.preventDefault();
  }

  function handleWheel(e) {
    e.preventDefault();
    let direction = e.deltaY < 0 ? 'up' : 'down';
    direction === 'up' ? handleWheelUp() : handleWheelDown();
  }

  function handleWheelUp() {
    return null;
  }

  function handleWheelDown() {
    handleRemove();
  }

  function handleRemove() {
    scrollTopNow();
    const doTrigger = setTimeout(() => {
      introTrigger();
    }, 0);
    const doHide = setTimeout(() => {
      introHide();
    }, 1000);
    return () => clearTimeout([doTrigger, doHide]);
  }

  return (
    <Wrapper fading={introTriggered}>
      {showVideo && <Video poster={data.datoCmsMisc.introImage.url} />}
      {!showVideo && (
        <MobileImage fluid={data.datoCmsMisc.introMobileImage.fluid} />
      )}
      <Down triggered={introTriggered} onClick={handleRemove}>
        <p>{data.datoCmsMisc.introTitle}</p>
        <img src={DownSrc} alt="Enter site" />
      </Down>
    </Wrapper>
  );
}

const VideoWrapper = styled.video`
  ${bz};
  transition: all 1s;
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  &::after {
    content: '';
    background: rgba(0, 28, 53, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4000;
  }
`;

const Video = ({ poster }) => {
  return (
    <Fragment>
      <VideoWrapper className="video-bg" poster={poster} loop muted autoPlay>
        <source src={Mp4Src} type="video/mp4" />
        <source src={WebmSrc} type="video/webm" />
      </VideoWrapper>
    </Fragment>
  );
};

Video.propTypes = videoTypes;
