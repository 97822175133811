import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery, Link } from 'gatsby';
import scrollToElement from 'scroll-to-element';

import { gradient, bz, z, font, colors } from '../consts/style';
import { headerTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import mq from '../style/mq';

const Wrapper = styled.div`
  background: transparent;
  position: fixed;
  top: 0;
  transform: translateY(${props => (!props.shown ? '-100%' : '0')});
  ${bz};
  transition: all 0.8s;
  left: 0;
  width: 100%;
  padding-top: 2rem;
  ${z.header};
  ${Inner} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu {
      &.mobile {
        display: none;
        ${mq.tabletSmall`
          display: block;
        `}
        button {
          ${gradient.red};
        }
      }
      &.desktop {
        ${mq.tabletSmall`
          display: none;
        `}
      }
      button {
        margin-left: 1.2rem;
        ${font.button};
        background: transparent;
        color: ${colors.grey};
        transition: background 0.4s ease;
        ${gradient.transparent};
        &:hover {
          ${gradient.red};
        }
      }
    }
  }
`;

const Image = styled(Img)`
  width: 20rem;
  ${mq.mobile`
    width: 16rem;
    `}
`;

const headerQuery = graphql`
  {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const MobileMenu = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${z.mobileMenu}
  ${gradient.default}
  button {
    width: 12rem;
    ${gradient.red};
    ${font.button};
    margin-bottom: 2rem;
    color: ${colors.grey};
    &.close {
      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
      width: 4rem;
      height: 4rem;
      &::after {
        content: '';
        height: 0.2rem;
        width: 3rem;
        background: ${colors.grey};
        transform: translateX(-50%) rotate(45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
      }
      &::before {
        content: '';
        height: 0.2rem;
        width: 3rem;
        background: ${colors.grey};
        transform: translateX(-50%) rotate(-45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
      }
    }
  }
`;

export default function Header({ shown }) {
  const data = useStaticQuery(headerQuery);
  const [menuOpen, setMenuOpen] = useState(false);

  function handleClick(val) {
    if (String(val).includes('#')) {
      setMenuOpen(false);
      return scrollToElement(val, { offset: -100 });
    }
    setMenuOpen(val);
  }

  return (
    <Fragment>
      <Wrapper shown={shown}>
        <Container>
          <Inner>
            <Link to="/">
              <Image fluid={data.logo.childImageSharp.fluid} />
            </Link>

            <nav className="menu desktop">
              <button onClick={() => handleClick('#watch')}>Watch</button>
              <button onClick={() => handleClick('#join')}>Join</button>
              <button onClick={() => handleClick('#listen')}>Listen</button>
              {/* <button onClick={() => handleClick('#see')}>See</button>
              <button onClick={() => handleClick('#read')}>Read</button> */}
            </nav>
            <div className="menu mobile">
              <button onClick={() => handleClick(true)}>Menu</button>
            </div>
          </Inner>
        </Container>
      </Wrapper>
      {menuOpen && (
        <MobileMenu>
          <button className="close" onClick={() => handleClick(false)} />
          <button onClick={() => handleClick('#watch')}>Watch</button>
          <button onClick={() => handleClick('#join')}>Join</button>
          <button onClick={() => handleClick('#listen')}>Listen</button>
          {/* <button onClick={() => handleClick('#see')}>See</button>
          <button onClick={() => handleClick('#read')}>Read</button> */}
        </MobileMenu>
      )}
    </Fragment>
  );
}

Header.propTypes = headerTypes;
