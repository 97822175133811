import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';

const Wrapper = styled.div`
  width: 100%;
  max-width: 100rem;
  .video-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default function Video({ videoId, provider }) {
  function renderPlayer() {
    if (provider === 'youtube') {
      return (
        <YouTube
          opts={{
            playerVars: {
              autoplay: 1,
            },
          }}
          videoId={videoId}
        />
      );
    }
    return <Vimeo video={videoId} autoplay />;
  }

  return (
    <Wrapper>
      <div className="video-wrapper">{renderPlayer()}</div>
    </Wrapper>
  );
}

Video.propTypes = {
  videoId: PropTypes.string,
  provider: PropTypes.string,
};
