import 'typeface-rubik';
import { createGlobalStyle } from 'styled-components';
import { gradient } from '../consts/style';

const Global = createGlobalStyle`

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  ${gradient.default};
}

`;

export default Global;
