/* eslint-disable quotes */
import bp from './breakpoints';

const mq = {
  mobile: `${bp.mobile}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  tablet: `${bp.tablet}px`,
  desktop: `${bp.desktop}px`,
};

export const bz = `
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  `;

export const gradient = {
  default: `
    background: radial-gradient(16.28% 45.46% at 53.17% 50%, #9E6493 0%, #6F5F73 100%);`,
  red: `background: linear-gradient(270deg, #C4936A 0%, #E46FA3 46.46%, #E460CA 100%);`,
  blue: `background: linear-gradient(270deg, #54639E 0%, #73E7E5 48.43%, #8083FC 100%);`,
  transparent: `background: linear-gradient(270deg, transparent 0%, transparent 48.43%, transparent 100%);`,
};

export const font = {
  h1: `
        font-size: 3.2rem;
        font-weight: bold;
        text-transform: uppercase;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 2rem;
        }
    `,
  h2: `
        font-size: 2.4rem;
        font-weight: bold;
        text-transform: uppercase;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 2rem;
        }
    `,
  body: `
        font-size: 1.8rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .1rem;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 1.6rem;
        }
    `,
  button: `
        font-size: 1.8rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .1rem;
        padding: 0.6rem 1.6rem;
        border-radius: 0.4rem;
        border: none;
        outline: none;
        @media screen and (max-width: ${mq.tablet}) {
            font-size: 1.6rem;
        }
    `,
};

export const colors = {
  dark: '#222',
  light: '#fff',
  grey: '#FAFAFA',
  purple: '#6e5f72',
};

export const z = {
  hero: `z-index: 200000;`,
  header: `z-index: 300000;`,
  mobileMenu: `z-index: 400000;`,
  video: `z-index: 500000;`,
};

export const duration = 500;
