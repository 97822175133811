import scrollToElement from 'scroll-to-element';

export const scrollTopNow = () => {
  if (typeof document !== 'undefined') {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};

export const scrollTo = target =>
  scrollToElement(target, {
    duration: 1500,
  });
